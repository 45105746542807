import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"
import { Info, Clock, MapPin, Phone, Mail, Linkedin } from "react-feather"
import Seo from "../components/seo"
import Logo from "../components/logoContact"

const Container = styled.div`
  margin: 0 20px 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
  @media screen and (min-width: 1050px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 88px 0 0;
  }
`

const Title = styled.h1`
  margin: 16px 0;
  color: #3e4c59;
  font-size: 28px;
  font-weight: bold;
  line-height: 140%;
  @media screen and (min-width: 1050px) {
    font-size: 24px;
    max-width: 60%;
    margin: 0;
  }
`
const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 24px 0 0;
  &:first-child {
    margin: 0;
  }
  & p {
    margin: 0;
    margin-left: 16px;
    font-size: 15px;
    font-weight: 500;
    @media screen and (min-width: 1050px) {
      font-size: 15px;
    }
  }
  & a {
    color: #1f2933;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (min-width: 1050px) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
`

const HideOnDesktop = styled.div`
  @media screen and (min-width: 1050px) {
    display: none;
  }
`
const HideOnMobile = styled.div`
  display: none;
  @media screen and (min-width: 1050px) {
    display: block;
  }
`

const StudioLogo = styled.h1`
  font-size: 14px;
  color: #3e4c59;
  font-weight: ${props => (props.thin ? "200" : "bold")};
  @media screen and (min-width: 1050px) {
    font-size: 16px;
    margin: 0;
  }
`
const FadeFromBottom = keyframes({
  from: {
    transform: "translateY(20px)",
  },
  to: {
    opacity: 1,
  },
})

const QuickDialItem = styled.div`
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  opacity: 0;
  animation: ${FadeFromBottom} 500ms ease forwards;
`

const QuickDialContainer = styled.div`
  width: 100%;
`

const ContattaciPage = ({ location }) => {
  let infoIcon
  let iconColor
  let menuIcon
  let linkStyle

  infoIcon = {
    size: "16",
    color: "#7B8794",
    style: { alignSelf: "center" },
  }
  linkStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
  iconColor = "#7B8794"
  menuIcon = {
    size: "32",
    color: "#3E4C59",
  }

  return (
    <>
      <Seo
        title="Contattaci"
        description="Prenota un appuntamento presso lo studio commercialisti associati Benetti Filippini | Brescia"
      />
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Title>
            Chiamaci o mandaci una mail per prenotare il tuo appuntamento.
            <br /> Ti risponderemo in breve tempo.
          </Title>
          <HideOnDesktop>
            <div></div>
          </HideOnDesktop>
          <HideOnMobile>
            <div style={{ display: "flex", marginTop: "72px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ marginRight: "32px" }}>
                  <StudioLogo thin>Studio commercialisti associati</StudioLogo>
                  <StudioLogo style={{ marginTop: "-4px" }}>
                    &mdash;Benetti Filippini
                  </StudioLogo>
                  <StudioLogo
                    thin
                    style={{
                      lineHeight: "125%",
                      fontSize: "14px",
                      marginTop: "8px",
                    }}
                  >
                    Rag. Mariacecilia Benetti <br />
                    Dott.Ssa Ombretta Filippini <br />
                    Cod.Fisc e P.Iva 03301570176
                  </StudioLogo>
                </div>
              </div>
              <div style={{ alignSelf: "flex-end" }}>
                <Logo />
              </div>
            </div>
          </HideOnMobile>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginRight: "64px",
          }}
        >
          <HideOnDesktop>
            <InfoContainer
              style={{
                alignItems: "flex-start",
                marginTop: "32px",
                justifyContent: "center",
              }}
            >
              <Info size="26" color={iconColor} />
              <p>
                Studio commercialisti associati Benetti Filippini <br /> Rag.
                Mariacecilia Benetti <br /> Dott.Ssa Ombretta Filippini <br />
                Cod.Fisc e P.Iva 03301570176
              </p>
            </InfoContainer>
          </HideOnDesktop>
          <InfoContainer style={{ alignItems: "flex-start" }}>
            <Clock {...infoIcon} style={{ marginTop: "4px" }} />
            <p>
              Da Lunedi a Venerdi <br /> Mattina 08:30 - 12:30 <br /> Pomeriggio
              14:00 - 18:30
            </p>
          </InfoContainer>
          <InfoContainer>
            <MapPin {...infoIcon} />
            <p>Via Malta n. 8 – 25124 Brescia</p>
          </InfoContainer>
          <InfoContainer>
            <Phone {...infoIcon} />
            <p>030/221067</p>
          </InfoContainer>

          <InfoContainer>
            <Mail {...infoIcon} />
            <a
              href="mailto:ceciliabenetti@benettifilippini.studio?cc=ombrettafilippini@benettifilippini.studio&subject=Richiesta%20contatto%20da%20benettifilippini.studio
              "
              rel="noopener noreferrer"
            >
              <p>ceciliabenetti@benettifilippini.studio</p>
            </a>
          </InfoContainer>
          <InfoContainer>
            <Mail {...infoIcon} />
            <a
              href="mailto:ombrettafilippini@benettifilippini.studio?cc=ceciliabenetti@benettifilippini.studio&subject=Richiesta%20contatto%20da%20benettifilippini.studio
              "
              rel="noopener noreferrer"
            >
              <HideOnMobile>
                <p>ombrettafilippini@benettifilippini.studio</p>
              </HideOnMobile>
              <HideOnDesktop>
                <p>ombrettafilippini@benettifilippini.st...</p>
              </HideOnDesktop>
            </a>
          </InfoContainer>
          <HideOnDesktop>
            <QuickDialContainer>
              <div
                style={{
                  position: "fixed",
                  bottom: "0",
                  right: 10,
                  left: 9,
                  display: "flex",
                  flex: "0 0 100",
                  marginBottom: "8px",
                  zIndex: "1",
                  justifyContent: "space-around",
                }}
              >
                <QuickDialItem style={{ animationDelay: "0" }}>
                  <a href="tel:+39030 221067" style={linkStyle}>
                    <Phone {...menuIcon} />
                  </a>
                </QuickDialItem>
                <QuickDialItem style={{ animationDelay: "100ms" }}>
                  <a
                    style={linkStyle}
                    href="mailto:ombrettafilippini@benettifilippini.studio?cc=ceciliabenetti@benettifilippini.studio&subject=Richiesta%20contatto%20da%20benettifilippini.studio
                    "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Mail {...menuIcon} />
                  </a>
                </QuickDialItem>
                <QuickDialItem style={{ animationDelay: "200ms" }}>
                  <a
                    style={linkStyle}
                    href="https://www.google.com/maps/place/Studio+Commercialisti+Ass.Ti+Benetti+Rag.+Maria+Cecilia+-+Filippini+Rag.+Ombretta/@45.5261906,10.2121632,17z/data=!3m1!4b1!4m5!3m4!1s0x478176747e10e1ed:0x293ae3e8353c9188!8m2!3d45.5261906!4d10.2143519"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MapPin {...menuIcon} />
                  </a>
                </QuickDialItem>
                <QuickDialItem style={{ animationDelay: "300ms" }}>
                  <a
                    href="https://www.linkedin.com/in/ombretta-filippini-68891733"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={linkStyle}
                  >
                    <Linkedin {...menuIcon} />
                  </a>
                </QuickDialItem>
              </div>
            </QuickDialContainer>
          </HideOnDesktop>
        </div>
      </Container>
    </>
  )
}

export default ContattaciPage
